
#root{
overflow-x: hidden;
background-size: contain;
background-size: 100px ;
background-position-x: center;
cursor: url('/src/assets/cursor-svgrepo-com.svg'), auto;
min-width: 370px;
font-family: 'Roboto Mono', monospace;
font-family: 'Tilt Neon', sans-serif;
}

:root{
  
  --main-color: #000000;
  color: var(--main-color);
}

.button-container div{
    background-color: transparent;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
    font-family: sans-serif;
    letter-spacing: 0.3em;
    width: 1/3;
    border: solid 1px rgb(138, 186, 237);
    border-left: 0;
    color: var(--main-color);
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mode-text{
  text-transform: uppercase;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: 0.3em;
  padding-left: 5px;
  padding-top: 10px;
}
.header-container{
    display: flex;
    flex-direction: row;

}
.button-container{
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo{
    width: 200px;
    height: 21px;
    padding-right: 10px;
    padding-left: 10px;
}
.logo-container{
    display: flex;
    align-items: center;
    border: solid 1px rgb(138, 186, 237);
}
.mode-toggle{
    border: solid 1px rgb(138, 186, 237);
}
.mode{
    margin: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu-container {
    position: fixed;
    height: 0;
    width: 100vw;
    background-color: white;
    z-index: 2222;
    overflow: hidden;
    transition: height 0.3s ease; /* Add a smooth transition effect */
    z-index: 34234234234234;
    background-size: contain;
    background-size: 100px ;
    background-position-x: center;
    display: flex;
    flex-direction: column;
  }
  .menu-bttn{
    border: solid 1px rgb(138, 186, 237);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 78px;
display: none;  }
  
  .menu-container.open {
    height: 100%; /* When the 'open' class is added, the menu expands to full height */

  }
  .x{
    position: absolute;
    right: 0;
    top: 0;
  }
  @media (max-width: 768px) {
     .menu-bttn {
      display:flex;
      width: 100%;
    }
    .button-container{
        display: none;
    }
    .mode-text{
        display: none;
    }
    .logo-container{
      width: 55%;
    }
  }
  @media (max-width: 900px) {
    .button-container div{
        letter-spacing: 0;
        font-size: 13px;
    }
    
  }
  .menu-logo{
    width: 100%;
  }
  .menu-items{
    padding-left: 20px;
    padding-bottom: 20px;
    font-size: 50px;
  }
  .menu-items:hover{
    padding-left: 30px;
    transform: scale(1.1);
  }

  
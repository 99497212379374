#projects {
    display: flex;
    flex-direction: column;
     height: 100%;
     justify-content: center;
  }
  
  .project {
    border: solid 1px rgb(138, 186, 237);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: transform 0.5s;
  }
  .projects{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    height: 100%;
  }
  .project img {
    width: 470px;
    height: 270px;
    object-fit: fill;

  }
 
  .projects-text{
    font-family: 'Roboto Mono', monospace;
    font-family: 'Tilt Neon', sans-serif;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .projects-text >p {
    text-align: center;
    width: 60%;
  }
  .image-container {
    width: 470px;
    height: 300px;
    transform-style: preserve-3d;
    transition: transform 0.5s;
  }
  
  .front-image {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .back {
    width: 470px;
    height: 300px;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotateY(180deg);
    background: #f0f0f036;
    display: none;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 0.9;
    backface-visibility: hidden;
  }
  
  .project:hover .image-container {
    transform: rotateY(180deg);
  }
  
  .project:hover .back {
    display: flex;
  }
  
  .projects-text {
    font-family: 'Roboto Mono', monospace;
    font-family: 'Tilt Neon', sans-serif;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .projects-text > p {
    text-align: center;
    width: 60%;
  }
  a{
padding: 10px;
    color: inherit;
  }
  @media (max-width: 768px) {
    .project{
      width: 100vw;
    }
    .image-container {
      width: 345px;
      height: 230px;
      transform-style: preserve-3d;
      transition: transform 0.5s;
    }
    .project img {
      width: 345px;
      height: 230px;
      object-fit: fill;
  
    }
    .back {
      width: 345px;
      height: 230px;
      position: absolute;
      top: 0;
      left: 0;
      transform: rotateY(180deg);
      background: #f0f0f036;
      display: none;
      align-items: center;
      justify-content: center;
      text-align: center;
      opacity: 0.9;
      backface-visibility: hidden;
    }
 }
.about-container {
  border-bottom: solid 1px rgb(138, 186, 237);

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  flex-direction: row;
  z-index: 0;
}
#about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto Mono', monospace;
  font-family: 'Tilt Neon', sans-serif;
}
.about-text {
  font-family: 'Roboto Mono', monospace;
  font-family: 'Tilt Neon', sans-serif;
  font-weight: 800;
  font-size: 60px;
  margin-bottom: 0;
  text-align: center;
}
.about-text-line-2 {
  margin: 0;
  font-family: 'Roboto Mono', monospace;
  font-family: 'Tilt Neon', sans-serif;
  font-weight: 800;
  font-size: 30px;
  text-align: center;
}
.about-me-2 {
  display: flex;
  margin-top: 56px;
  flex-direction: column;
}
.skills-exp {
  display: flex;
  height: 100%;
}

#experience,
#skills {
  border: solid 1px rgb(138, 186, 237);
  font-family: 'Roboto Mono', monospace;
  font-family: 'Tilt Neon', sans-serif;
  flex: 1;
  padding: 10px;
}
ul > p {
  margin: 0;
}
li {
  list-style: 0;
}

#about > h2 {
  font-family: 'Roboto Mono', monospace;
  font-family: 'Tilt Neon', sans-serif;
  font-size: 25px;
  text-align: center;
}
#skills > h2 {
  font-family: 'Roboto Mono', monospace;
  font-family: 'Tilt Neon', sans-serif;
  font-size: 25px;
  text-align: center;
}
#experience > h2 {
  font-family: 'Roboto Mono', monospace;
  font-family: 'Tilt Neon', sans-serif;
  font-size: 25px;
  text-align: center;
}
@media (max-width: 768px) {
  .about-text {
    font-size: 40px;
  }

  .skills-exp {
    display: flex;
    flex-direction: column;
  }
}
#about > p {
  text-align: center;
  width: 60%;
}
.skills-ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
}
.skills-ul > li {
  list-style: none;
  margin: 10px;
  padding: 5px;
}
#about > p{
  padding-bottom: 150px;
}
.contact-text{
    text-align: center;
    padding-top: 150px;
    padding-bottom: 150px;
}
#contact{
    width: 100%;
}
.contacts{
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
}
.contactMe{
    flex: 1;
    border: solid 1px rgb(138, 186, 237);
    padding: 10px;
}
.socials{
    flex: 1;
    border: solid 1px rgb(138, 186, 237);
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: center;
   
}
.socials div{
    display: flex;
    flex-direction: row;
}
@media (max-width: 768px) {
    .contacts{
        display: flex;
        flex-direction: column;
    }
}